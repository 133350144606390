import "./App.css";

function App() {
  return (
    <>
      <div className="header-wrap">
        <header>
          <img id="logo" src="/Hesed_White.png" alt="logo" />
        </header>
      </div>
      <main>
        <div class="row">
          <h1>Thats What Hesed?</h1>
          <p>
            Let me guess, the reason that you are here is probably because you
            have seen one of our logos and you are curious as to what it means
            (and why is “he said” misspelled)? First of all let me say “thank
            you” for taking the time to gain information and knowledge. I have
            seen some very clever bumper stickers that at first glance I did not
            understand what was being said, but after further investigation I
            was able to understand the meaning.
            <span>
              I am not going to lie, with some of them I wish that I had
              remained ignorant of their meaning!
            </span>
            For the past few years I am sure that you have noticed that things
            in our world have been quickly spiraling out of control. Since the
            onset of Covid -19 we have been shut down, shut out, masked,
            isolated, restricted, mandated, and divided into two groups known as
            “essential” and “non-essential”. We could not go to restaurants,
            movies, malls or even gather with loved ones. Hatred, intolerance
            and vitriol have replaced common decency, respect and compassion.
            Everyone is being forced to choose sides, whatever those sides may
            be. “If you do not agree with my opinion then you are a{" "}
            <span>fill in the blank</span> , I am right and you are wrong”,
            which is then followed by a mic drop. It doesn’t matter who you are,
            someone is going to be offended by your opinions, by what you say or
            what you do…and then comes the <span>attack!</span>
          </p>
        </div>

        <div className="secondary-wrapper">
          <div className="row secondary left">
            <h1>How Are We to Respond?</h1>
            <p>
              There is a verse in the Bible that basically tells us how we are
              to behave… MICAH 6:8 “He has shown you, O mortal, what is good.
              And what does the LORD require of you? To act justly and to love
              mercy and to walk humbly with your God.” Depending on how old you
              are, you may remember the days before the internet when one of the
              ways to exchange information was through the mail in the form of a
              letter. Basically every letter consisted of a few very important
              things;
            </p>
            <ul>
              <li>
                <strong>The Address</strong> - which contains the name of the
                recipient, usually written across the main part of the envelope
                or package.
              </li>
              <li>
                <strong>The Sender</strong> - which is the name of the one
                sending the contents of the envelope or package, usually written
                in the upper left hand corner.
              </li>
              <li>
                <strong>The Contents</strong> - which are enclosed in the
                envelope or package.
              </li>
            </ul>
          </div>
        </div>

        <div class="row left">
          <h1>Micah 6:8 Has All The Components of An Important Letter</h1>
          <p>
            “He has shown you, O mortal, what is good.” First of all let’s look
            at the phrase “O mortal”. The Hebrew word here is ‘adam’, and it
            means ‘mankind or human being’. This means you and I, this means
            everybody, there is no special designation given to anyone based on
            color, religion, creed, nationality or political bent. So this
            letter is addressed to “O mortal” which means you and I, everyone
            you and I have ever met or will ever meet, as well as those whom you
            and I have never met. Now that we know who this important letter is
            addressed to, let’s see if we can find out who sent it… “And what
            does the LORD require of you?” There it is…in the upper left hand
            corner we can see that “the LORD” has sent this letter to you and I,
            and if He has taken the time to have this recorded and sent then it
            must be important. So we know that the letter is addressed to us,
            and we know that the LORD has sent it, the only thing left to do is
            find out what is in it… As we read the contents we discover that
            this important letter gives us three requirements on how we are to
            live our lives. We have all heard the question, “What is the secret
            to life?” If you were to ask 100 people this question, you would
            probably get 100 different opinions, but I believe that this single
            little verse answers that question perfectly. So what does it
            say?...
          </p>
          <ul>
            <li>
              <strong>Act Justly</strong> - The Hebrew word here is mispat
              (mish-pawt’) which means - a verdict (favorable or unfavorable)
              pronounced judicially, especially a sentence or formal decree,
              divine law, (individual or collective), including the act, the
              place, the suit, the crime, and the penalty; In layman’s terms it
              means that we should not judge the behavior of others unless we
              have all of the information regarding the case against the
              defendant…which is impossible! I believe that’s the reason the
              Bible says that we are not to judge anyone, because we do not have
              all of the facts in order to judge fairly or justly.
            </li>
            <li>
              <strong>Love Mercy</strong> - The Hebrew word for mercy is HESED
              which means; goodness, kindness, faithfulness, mercy, grace,
              compassion, steadfast love, covenant love, faithful love, loving
              kindness, unfailing love Zeal towards anyone, love, kindness,
              especially people amongst themselves mercy, pity, when referring
              to those in misfortune, to act kindly towards Piety or reverence
              of men towards God To put it plainly, the word “Hesed” is used of
              love and mercy of God towards humanity, reverence of people
              towards God, as well as kindness or love between people.
            </li>
            <li>
              <strong>Walk Humbly With Your God</strong> - The full meaning of Hesed leads us to the third part of the contents of our letter…
            </li>
          </ul>
        </div>
        <div className="row secondary">
          <ul className="imageList">
            <li>
              <h3>Love and Mercy of God towards humanity</h3>
              <img src="/FromGod.png" alt="FromGod" />
            </li>
            <li>
              <h3>Reverence of people twards God</h3>
              <img src="/TwardsGod.png" alt="TwardsGod" />
            </li>
            <li>
              <h3>Kindness or love between people</h3>
              <img src="/BetweenPeople.png" alt="BetweenPeople" />
            </li>
          </ul>
        </div>
        <div className="row footer">
          <img className="fullTriangle" src="/Hesed_White_NoURL.png" alt="FullTriangle" />
          <p>When we reciprocate God’s love back to him, we are then capable of “acting justly” and “loving mercy” when it comes to dealing with one another. When we are truly practicing “Hesed” then we have a perfectly unbroken triangle…</p>
        </div>
      </main>
    </>
  );
}

export default App;
